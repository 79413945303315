tinymce.addI18n('fa',{
"Redo": "\u0628\u0627\u0632\u0627\u0646\u062c\u0627\u0645",
"Undo": "\u0648\u0627\u06af\u0631\u062f",
"Cut": "\u0628\u0631\u0634",
"Copy": "\u06a9\u067e\u06cc",
"Paste": "\u0686\u0633\u0628\u0627\u0646\u062f\u0646",
"Select all": "\u0627\u0646\u062a\u062e\u0627\u0628 \u0647\u0645\u0647",
"New document": "\u0633\u0646\u062f \u062c\u062f\u06cc\u062f",
"Ok": "\u062a\u0623\u06cc\u06cc\u062f",
"Cancel": "\u0644\u063a\u0648",
"Visual aids": "\u06a9\u0645\u06a9\u200c\u0647\u0627\u06cc \u0628\u0635\u0631\u06cc",
"Bold": "\u067e\u0631\u0631\u0646\u06af",
"Italic": "\u06a9\u062c",
"Underline": "\u0632\u06cc\u0631 \u062e\u0637 \u062f\u0627\u0631",
"Strikethrough": "\u062e\u0637 \u0632\u062f\u0646",
"Superscript": "\u0628\u0627\u0644\u0627\u0646\u06af\u0627\u0634\u062a",
"Subscript": "\u0632\u06cc\u0631\u0646\u06af\u0627\u0634\u062a",
"Clear formatting": "\u067e\u0627\u06a9 \u06a9\u0631\u062f\u0646 \u0642\u0627\u0644\u0628\u200c\u0628\u0646\u062f\u06cc",
"Align left": "\u062a\u0631\u0627\u0632\u0628\u0646\u062f\u06cc \u0627\u0632 \u0686\u067e",
"Align center": "\u062a\u0631\u0627\u0632\u0628\u0646\u062f\u06cc \u0627\u0632 \u0648\u0633\u0637",
"Align right": "\u062a\u0631\u0627\u0632\u0628\u0646\u062f\u06cc \u0627\u0632 \u0631\u0627\u0633\u062a",
"Justify": "\u062a\u0631\u0627\u0632\u0628\u0646\u062f\u06cc \u062f\u0648\u0637\u0631\u0641\u0647",
"Bullet list": "\u0641\u0647\u0631\u0633\u062a \u0646\u0634\u0627\u0646\u0647\u200c\u062f\u0627\u0631",
"Numbered list": "\u0641\u0647\u0631\u0633\u062a \u0634\u0645\u0627\u0631\u0647\u200c\u062f\u0627\u0631",
"Decrease indent": "\u06a9\u0627\u0647\u0634 \u062a\u0648\u0631\u0641\u062a\u06af\u06cc",
"Increase indent": "\u0627\u0641\u0632\u0627\u06cc\u0634 \u062a\u0648\u0631\u0641\u062a\u06af\u06cc",
"Close": "\u0628\u0633\u062a\u0646",
"Formats": "\u0642\u0627\u0644\u0628\u200c\u0628\u0646\u062f\u06cc\u200c\u0647\u0627",
"Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "\u0645\u0631\u0648\u0631\u06af\u0631 \u0634\u0645\u0627 \u0627\u0632 \u062f\u0633\u062a\u0631\u0633\u06cc \u0645\u0633\u062a\u0642\u06cc\u0645 \u0628\u0647 \u06a9\u0644\u06cc\u067e\u200c\u0628\u0648\u0631\u062f \u067e\u0634\u062a\u06cc\u0628\u0627\u0646\u06cc \u0646\u0645\u06cc\u200c\u06a9\u0646\u062f\u060c \u0644\u0637\u0641\u0627\u064b \u0627\u0632 \u0645\u06cc\u0627\u0646\u0628\u0631\u0647\u0627\u06cc Ctrl+X\/C\/V \u0635\u0641\u062d\u0647 \u06a9\u0644\u06cc\u062f \u0627\u0633\u062a\u0641\u0627\u062f\u0647 \u06a9\u0646\u06cc\u062f.",
"Headers": "\u0633\u0631\u0628\u0631\u06af\u200c\u0647\u0627",
"Header 1": "\u0633\u0631\u0628\u0631\u06af 1",
"Header 2": "\u0633\u0631\u0628\u0631\u06af 2",
"Header 3": "\u0633\u0631\u0628\u0631\u06af 3",
"Header 4": "\u0633\u0631\u0628\u0631\u06af 4",
"Header 5": "\u0633\u0631\u0628\u0631\u06af 5",
"Header 6": "\u0633\u0631\u0628\u0631\u06af 6",
"Headings": "\u0633\u0631\u0641\u0635\u0644\u200c\u0647\u0627",
"Heading 1": "\u0633\u0631\u0641\u0635\u0644 1",
"Heading 2": "\u0633\u0631\u0641\u0635\u0644 2",
"Heading 3": "\u0633\u0631\u0641\u0635\u0644 3",
"Heading 4": "\u0633\u0631\u0641\u0635\u0644 4",
"Heading 5": "\u0633\u0631\u0641\u0635\u0644 5",
"Heading 6": "\u0633\u0631\u0641\u0635\u0644 6",
"Preformatted": "\u0627\u0632 \u067e\u06cc\u0634 \u0642\u0627\u0644\u0628\u200c\u0628\u0646\u062f\u06cc\u200c\u0634\u062f\u0647",
"Div": "\u0628\u062e\u0634",
"Pre": "\u067e\u06cc\u0634",
"Code": "\u06a9\u062f",
"Paragraph": "\u067e\u0627\u0631\u0627\u06af\u0631\u0627\u0641",
"Blockquote": "\u0646\u0642\u0644 \u0642\u0648\u0644 \u0628\u0644\u0648\u06a9\u06cc",
"Inline": "\u0647\u0645\u200c\u0631\u0627\u0633\u062a\u0627",
"Blocks": "\u0628\u0644\u0648\u06a9\u200c\u0647\u0627",
"Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "\u0686\u0633\u0628\u0627\u0646\u062f\u0646 \u0647\u0645 \u0627\u06a9\u0646\u0648\u0646 \u062f\u0631 \u062d\u0627\u0644\u062a \u0645\u062a\u0646 \u0633\u0627\u062f\u0647 \u0627\u0633\u062a. \u062a\u0627 \u0632\u0645\u0627\u0646\u06cc \u06a9\u0647 \u0627\u06cc\u0646 \u062d\u0627\u0644\u062a \u0631\u0627 \u063a\u06cc\u0631\u200c\u0641\u0639\u0627\u0644 \u0646\u06a9\u0646\u06cc\u062f\u060c \u0645\u062d\u062a\u0648\u0627 \u062f\u0631 \u062d\u0627\u0644\u062a \u0645\u062a\u0646 \u0633\u0627\u062f\u0647 \u0627\u0636\u0627\u0641\u0647 \u0645\u06cc\u200c\u0634\u0648\u062f.",
"Fonts": "\u0641\u0648\u0646\u062a\u200c\u200c\u0647\u0627",
"Font Sizes": "\u0627\u0646\u062f\u0627\u0632\u0647\u0654 \u0641\u0648\u0646\u062a",
"Class": "\u0637\u0628\u0642\u0647",
"Browse for an image": "\u06af\u0634\u062a\u0646 \u0628\u0631\u0627\u06cc \u0639\u06a9\u0633 \u0645\u0648\u0631\u062f \u0646\u0638\u0631",
"OR": "OR",
"Drop an image here": "\u062a\u0635\u0648\u06cc\u0631 \u0645\u0648\u0631\u062f \u0646\u0638\u0631 \u0631\u0627 \u0627\u06cc\u0646\u062c\u0627 \u0631\u0647\u0627 \u06a9\u0646\u06cc\u062f",
"Upload": "\u0622\u067e\u0644\u0648\u062f",
"Block": "\u0628\u0644\u0648\u06a9",
"Align": "\u062a\u0631\u0627\u0632\u0628\u0646\u062f\u06cc",
"Default": "\u067e\u06cc\u0634\u0641\u0631\u0636",
"Circle": "\u062f\u0627\u06cc\u0631\u0647",
"Disc": "\u062f\u06cc\u0633\u06a9",
"Square": "\u0645\u0631\u0628\u0639",
"Lower Alpha": "\u0622\u0644\u0641\u0627\u0621 \u06a9\u0648\u0686\u06a9",
"Lower Greek": "\u06cc\u0648\u0646\u0627\u0646\u06cc \u06a9\u0648\u0686\u06a9",
"Lower Roman": "\u0631\u0648\u0645\u06cc \u06a9\u0648\u0686\u06a9",
"Upper Alpha": "\u0622\u0644\u0641\u0627\u0621 \u0628\u0632\u0631\u06af",
"Upper Roman": "\u0631\u0648\u0645\u06cc \u0628\u0632\u0631\u06af",
"Anchor...": "\u0642\u0644\u0627\u0628...",
"Name": "\u0646\u0627\u0645",
"Id": "Id",
"Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.": "Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.",
"You have unsaved changes are you sure you want to navigate away?": "\u0634\u0645\u0627 \u062a\u063a\u06cc\u06cc\u0631\u0627\u062a \u0630\u062e\u06cc\u0631\u0647 \u0646\u0634\u062f\u0647 \u0627\u06cc \u062f\u0627\u0631\u06cc\u062f\u060c \u0622\u06cc\u0627 \u0645\u0637\u0645\u0626\u0646\u06cc\u062f \u06a9\u0647 \u0645\u06cc\u062e\u0648\u0627\u0647\u06cc\u062f \u0627\u0632 \u0627\u06cc\u0646 \u0635\u0641\u062d\u0647 \u0628\u0631\u0648\u06cc\u062f\u061f",
"Restore last draft": "\u0628\u0627\u0632\u06af\u0631\u062f\u0627\u0646\u062f\u0646 \u0622\u062e\u0631\u06cc\u0646 \u067e\u06cc\u0634 \u0646\u0648\u06cc\u0633",
"Special character...": "\u0646\u0648\u06cc\u0633\u06c0 \u0648\u06cc\u0698\u0647...",
"Source code": "\u06a9\u062f \u0645\u0646\u0628\u0639",
"Insert\/Edit code sample": "Insert\/Edit code sample",
"Language": "Language",
"Code sample...": "\u0646\u0645\u0648\u0646\u0647 \u06a9\u062f...",
"Color Picker": "\u0627\u0646\u062a\u062e\u0627\u0628\u200c\u06a9\u0646\u0646\u062f\u0647 \u0631\u0646\u06af",
"R": "\u0642\u0631\u0645\u0632",
"G": "\u0633\u0628\u0632",
"B": "\u0622\u0628\u06cc",
"Left to right": "\u0686\u067e \u0628\u0647 \u0631\u0627\u0633\u062a",
"Right to left": "\u0631\u0627\u0633\u062a \u0628\u0647 \u0686\u067e",
"Emoticons...": "\u0635\u0648\u0631\u062a\u06a9\u200c\u0647\u0627...",
"Metadata and Document Properties": "\u0641\u0631\u0627\u062f\u0627\u062f\u0647 \u0648 \u0645\u0634\u062e\u0635\u0627\u062a \u0633\u0646\u062f",
"Title": "\u0639\u0646\u0648\u0627\u0646",
"Keywords": "\u06a9\u0644\u0645\u0627\u062a \u06a9\u0644\u06cc\u062f\u06cc",
"Description": "\u062a\u0648\u0636\u06cc\u062d\u0627\u062a",
"Robots": "\u0631\u0628\u0627\u062a\u200c\u0647\u0627",
"Author": "\u0646\u0648\u06cc\u0633\u0646\u062f\u0647",
"Encoding": "\u06a9\u062f \u06af\u0630\u0627\u0631\u06cc",
"Fullscreen": "\u062a\u0645\u0627\u0645 \u0635\u0641\u062d\u0647",
"Action": "\u0627\u0642\u062f\u0627\u0645",
"Shortcut": "\u0645\u06cc\u0627\u0646\u0628\u0631",
"Help": "\u0631\u0627\u0647\u0646\u0645\u0627",
"Address": "\u0622\u062f\u0631\u0633",
"Focus to menubar": "\u062a\u0645\u0631\u06a9\u0632 \u0628\u0631 \u0646\u0648\u0627\u0631 \u0645\u0646\u0648",
"Focus to toolbar": "\u062a\u0645\u0631\u06a9\u0632 \u0628\u0631 \u0646\u0648\u0627\u0631 \u0627\u0628\u0632\u0627\u0631",
"Focus to element path": "\u062a\u0645\u0631\u06a9\u0632 \u0628\u0631 \u0645\u0633\u06cc\u0631 \u0627\u0644\u0645\u0627\u0646",
"Focus to contextual toolbar": "\u062a\u0645\u0631\u06a9\u0632 \u0628\u0631 \u0646\u0648\u0627\u0631 \u0627\u0628\u0632\u0627\u0631 \u0628\u0627\u0641\u062a\u0627\u0631\u06cc",
"Insert link (if link plugin activated)": "\u062f\u0631\u062c \u067e\u06cc\u0648\u0646\u062f (\u062f\u0631 \u0635\u0648\u0631\u062a \u0641\u0639\u0627\u0644 \u0628\u0648\u062f\u0646 \u0627\u0641\u0632\u0648\u0646\u0647\u0654 \u067e\u06cc\u0648\u0646\u062f)",
"Save (if save plugin activated)": "\u0630\u062e\u06cc\u0631\u0647\u00a0(\u062f\u0631 \u0635\u0648\u0631\u062a \u0641\u0639\u0627\u0644 \u0628\u0648\u062f\u0646 \u0627\u0641\u0632\u0648\u0646\u0647\u0654 \u0630\u062e\u06cc\u0631\u0647)",
"Find (if searchreplace plugin activated)": "\u06cc\u0627\u0641\u062a\u0646 (\u062f\u0631 \u0635\u0648\u0631\u062a \u0641\u0639\u0627\u0644 \u0628\u0648\u062f\u0646 \u0627\u0641\u0632\u0648\u0646\u0647\u0654 \u062c\u0633\u062a\u062c\u0648\/\u062c\u0627\u06cc\u06af\u0632\u06cc\u0646\u06cc)",
"Plugins installed ({0}):": "\u0627\u0641\u0632\u0648\u0646\u0647\u200c\u0647\u0627\u06cc \u0646\u0635\u0628\u200c\u0634\u062f\u0647 ({0}):",
"Premium plugins:": "\u0627\u0641\u0632\u0648\u0646\u0647\u200c\u0647\u0627\u06cc \u067e\u0648\u0644\u06cc:",
"Learn more...": "\u06cc\u0627\u062f\u06af\u06cc\u0631\u06cc \u0628\u06cc\u0634\u062a\u0631...",
"You are using {0}": "\u062f\u0631 \u062d\u0627\u0644 \u0627\u0633\u062a\u0641\u0627\u062f\u0647 \u0627\u0632 {0} \u0647\u0633\u062a\u06cc\u062f",
"Plugins": "\u0627\u0641\u0632\u0648\u0646\u0647\u200c\u0647\u0627",
"Handy Shortcuts": "\u0645\u06cc\u0627\u0646\u0628\u0631\u0647\u0627\u06cc \u0645\u0641\u06cc\u062f",
"Horizontal line": "\u062e\u0637 \u0627\u0641\u0642\u06cc",
"Insert\/edit image": "\u0627\u0636\u0627\u0641\u0647\/\u0648\u06cc\u0631\u0627\u06cc\u0634 \u06a9\u0631\u062f\u0646 \u062a\u0635\u0648\u06cc\u0631",
"Image description": "\u062a\u0648\u0636\u06cc\u062d\u0627\u062a \u0639\u06a9\u0633",
"Source": "\u0645\u0646\u0628\u0639",
"Dimensions": "\u0627\u0628\u0639\u0627\u062f",
"Constrain proportions": "\u062d\u0641\u0638 \u062a\u0646\u0627\u0633\u0628",
"General": "\u0639\u0645\u0648\u0645\u06cc",
"Advanced": "\u067e\u06cc\u0634\u0631\u0641\u062a\u0647",
"Style": "\u0633\u0628\u06a9",
"Vertical space": "\u0641\u0636\u0627\u06cc \u0639\u0645\u0648\u062f\u06cc",
"Horizontal space": "\u0641\u0636\u0627\u06cc \u0627\u0641\u0642\u06cc",
"Border": "\u062d\u0627\u0634\u06cc\u0647",
"Insert image": "\u0627\u0636\u0627\u0641\u0647 \u06a9\u0631\u062f\u0646 \u062a\u0635\u0648\u06cc\u0631",
"Image...": "\u062a\u0635\u0648\u06cc\u0631...",
"Image list": "\u0641\u0647\u0631\u0633\u062a \u062a\u0635\u0648\u06cc\u0631",
"Rotate counterclockwise": "Rotate counterclockwise",
"Rotate clockwise": "Rotate clockwise",
"Flip vertically": "Flip vertically",
"Flip horizontally": "Flip horizontally",
"Edit image": "Edit image",
"Image options": "Image options",
"Zoom in": "Zoom in",
"Zoom out": "Zoom out",
"Crop": "Crop",
"Resize": "Resize",
"Orientation": "Orientation",
"Brightness": "Brightness",
"Sharpen": "Sharpen",
"Contrast": "Contrast",
"Color levels": "Color levels",
"Gamma": "Gamma",
"Invert": "Invert",
"Apply": "Apply",
"Back": "Back",
"Insert date\/time": "\u0627\u0636\u0627\u0641\u0647 \u06a9\u0631\u062f\u0646 \u062a\u0627\u0631\u06cc\u062e\/\u0632\u0645\u0627\u0646",
"Date\/time": "Date\/time",
"Insert\/Edit Link": "\u062f\u0631\u062c\/\u0648\u06cc\u0631\u0627\u06cc\u0634 \u067e\u06cc\u0648\u0646\u062f",
"Insert\/edit link": "\u0627\u0636\u0627\u0641\u0647\/\u0648\u06cc\u0631\u0627\u06cc\u0634 \u06a9\u0631\u062f\u0646 \u0644\u06cc\u0646\u06a9",
"Text to display": "\u0645\u062a\u0646 \u0628\u0631\u0627\u06cc \u0646\u0645\u0627\u06cc\u0634",
"Url": "\u0627\u062f\u0631\u0633 \u0644\u06cc\u0646\u06a9",
"Open link in...": "\u0628\u0627\u0632 \u06a9\u0631\u062f\u0646 \u067e\u06cc\u0648\u0646\u062f \u062f\u0631...",
"Current window": "\u067e\u0646\u062c\u0631\u0647 \u062c\u0627\u0631\u06cc",
"None": "\u0647\u06cc\u0686 \u06a9\u062f\u0627\u0645",
"New window": "\u067e\u0646\u062c\u0631\u0647 \u062c\u062f\u06cc\u062f",
"Remove link": "\u062d\u0630\u0641 \u0644\u06cc\u0646\u06a9",
"Anchors": "\u0644\u0646\u06af\u0631 - \u0644\u06cc\u0646\u06a9 \u062f\u0627\u062e\u0644 \u0635\u0641\u062d\u0647",
"Link...": "\u067e\u06cc\u0648\u0646\u062f...",
"Paste or type a link": "Paste or type a link",
"The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?",
"The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?",
"Link list": "\u0641\u0647\u0631\u0633\u062a \u067e\u06cc\u0648\u0646\u062f\u0647\u0627",
"Insert video": "\u0627\u0636\u0627\u0641\u0647 \u06a9\u0631\u062f\u0646 \u0641\u0627\u06cc\u0644 \u062a\u0635\u0648\u06cc\u0631\u06cc",
"Insert\/edit video": "\u0627\u0636\u0627\u0641\u0647\/\u0648\u06cc\u0631\u0627\u06cc\u0634 \u06a9\u0631\u062f\u0646 \u0641\u0627\u06cc\u0644 \u062a\u0635\u0648\u06cc\u0631\u06cc",
"Insert\/edit media": "Insert\/edit media",
"Alternative source": "\u0645\u0646\u0628\u0639 \u062f\u06cc\u06af\u0631",
"Alternative source URL": "\u0646\u0634\u0627\u0646\u06cc \u0648\u0628 \u0645\u0646\u0628\u0639 \u062c\u0627\u06cc\u06af\u0632\u06cc\u0646",
"Media poster (Image URL)": "\u067e\u0648\u0633\u062a\u0631 \u0631\u0633\u0627\u0646\u0647 (\u0646\u0634\u0627\u0646\u06cc \u0648\u0628 \u062a\u0635\u0648\u06cc\u0631)",
"Paste your embed code below:": "\u06a9\u062f \u062e\u0648\u062f \u0631\u0627 \u0628\u0631\u0627\u06cc \u062c\u0627 \u062f\u0627\u062f\u0646 \u062f\u0631 \u0633\u0627\u06cc\u062a - embed - \u060c \u062f\u0631 \u0632\u06cc\u0631 \u0642\u0631\u0627\u0631 \u062f\u0647\u06cc\u062f:",
"Embed": "\u062c\u0627 \u062f\u0627\u062f\u0646",
"Media...": "\u0631\u0633\u0627\u0646\u0647...",
"Nonbreaking space": "\u0641\u0636\u0627\u06cc \u063a\u06cc\u0631 \u0634\u06a9\u0633\u062a\u0646",
"Page break": "\u0634\u06a9\u0633\u062a\u0646 \u0635\u0641\u062d\u0647",
"Paste as text": "\u0686\u0633\u0628\u0627\u0646\u062f\u0646 \u0628\u0647 \u0639\u0646\u0648\u0627\u0646 \u0645\u062a\u0646",
"Preview": "\u067e\u06cc\u0634 \u0646\u0645\u0627\u06cc\u0634",
"Print...": "\u0686\u0627\u067e...",
"Save": "\u0630\u062e\u06cc\u0631\u0647",
"Find": "\u062c\u0633\u062a\u200c\u0648\u200c\u062c\u0648",
"Replace with": "\u062c\u0627\u06cc\u06af\u0632\u06cc\u0646 \u06a9\u0631\u062f\u0646 \u0628\u0627",
"Replace": "\u062c\u0627\u06cc\u06af\u0632\u06cc\u0646 \u06a9\u0631\u062f\u0646",
"Replace all": "\u062c\u0627\u06cc\u06af\u0632\u06cc\u0646 \u06a9\u0631\u062f\u0646 \u0647\u0645\u0647",
"Previous": "\u0642\u0628\u0644\u06cc",
"Next": "\u0628\u0639\u062f\u06cc",
"Find and replace...": "\u06cc\u0627\u0641\u062a\u0646 \u0648 \u062c\u0627\u06cc\u06af\u0632\u06cc\u0646 \u06a9\u0631\u062f\u0646...",
"Could not find the specified string.": "\u0631\u0634\u062a\u0647 \u0645\u062a\u0646\u06cc \u0645\u0648\u0631\u062f \u0646\u0638\u0631 \u067e\u06cc\u062f\u0627 \u0646\u0634\u062f.",
"Match case": "\u062d\u0633\u0627\u0633 \u0628\u0647 \u062d\u0631\u0648\u0641 \u06a9\u0648\u0686\u06a9 \u0648 \u0628\u0632\u0631\u06af",
"Find whole words only": "\u06cc\u0627\u0641\u062a\u0646 \u062f\u0642\u06cc\u0642\u0627\u064b \u06a9\u0644 \u0648\u0627\u0698\u0647",
"Spell check": "\u0628\u0631\u0631\u0633\u06cc \u0627\u0645\u0644\u0627",
"Ignore": "\u0646\u0627\u062f\u06cc\u062f\u0647 \u06af\u0631\u0641\u062a\u0646",
"Ignore all": "\u0646\u0627\u062f\u06cc\u062f\u0647 \u06af\u0631\u0641\u062a\u0646 \u0647\u0645\u0647",
"Finish": "\u067e\u0627\u06cc\u0627\u0646",
"Add to Dictionary": "Add to Dictionary",
"Insert table": "\u0627\u0636\u0627\u0641\u0647 \u06a9\u0631\u062f\u0646 \u062c\u062f\u0648\u0644",
"Table properties": "\u0648\u06cc\u0698\u06af\u06cc\u200c\u0647\u0627\u06cc \u062c\u062f\u0648\u0644",
"Delete table": "\u062d\u0630\u0641 \u062c\u062f\u0648\u0644",
"Cell": "\u0633\u0644\u0648\u0644",
"Row": "\u0633\u0637\u0631",
"Column": "\u0633\u062a\u0648\u0646",
"Cell properties": "\u0648\u06cc\u0698\u06af\u06cc\u200c\u0647\u0627\u06cc \u0633\u0644\u0648\u0644",
"Merge cells": "\u0627\u062f\u063a\u0627\u0645 \u0633\u0644\u0648\u0644\u200c\u0647\u0627",
"Split cell": "\u062a\u0642\u0633\u06cc\u0645 \u0633\u0644\u0648\u0644 \u062c\u062f\u0648\u0644",
"Insert row before": "\u0627\u0636\u0627\u0641\u0647 \u06a9\u0631\u062f\u0646 \u0633\u0637\u0631 \u062c\u062f\u06cc\u062f \u0642\u0628\u0644 \u0627\u0632 \u0627\u06cc\u0646 \u0633\u0637\u0631",
"Insert row after": "\u0627\u0636\u0627\u0641\u0647 \u06a9\u0631\u062f\u0646 \u0633\u0637\u0631 \u062c\u062f\u06cc\u062f \u0628\u0639\u062f \u0627\u0632 \u0627\u06cc\u0646 \u0633\u0637\u0631",
"Delete row": "\u062d\u0630\u0641 \u0633\u0637\u0631",
"Row properties": "\u0648\u06cc\u0698\u06af\u06cc\u200c\u0647\u0627\u06cc \u0633\u0637\u0631",
"Cut row": "\u0628\u0631\u0634 \u0633\u0637\u0631",
"Copy row": "\u06a9\u067e\u06cc \u0633\u0637\u0631",
"Paste row before": "\u0686\u0633\u0628\u0627\u0646\u062f\u0646 \u0633\u0637\u0631\u060c \u0642\u0628\u0644 \u0627\u0632 \u0627\u06cc\u0646 \u0633\u0637\u0631",
"Paste row after": "\u0686\u0633\u0628\u0627\u0646\u062f\u0646 \u0633\u0637\u0631\u060c \u0628\u0639\u062f \u0627\u0632 \u0627\u06cc\u0646 \u0633\u0637\u0631",
"Insert column before": "\u0627\u0636\u0627\u0641\u0647 \u06a9\u0631\u062f\u0646 \u0633\u062a\u0648\u0646 \u062c\u062f\u06cc\u062f \u0642\u0628\u0644 \u0627\u0632 \u0627\u06cc\u0646 \u0633\u062a\u0648\u0646",
"Insert column after": "\u0627\u0636\u0627\u0641\u0647 \u06a9\u0631\u062f\u0646 \u0633\u062a\u0648\u0646 \u062c\u062f\u06cc\u062f \u0628\u0639\u062f \u0627\u0632 \u0627\u06cc\u0646 \u0633\u062a\u0648\u0646",
"Delete column": "\u062d\u0630\u0641 \u0633\u062a\u0648\u0646",
"Cols": "\u062a\u0639\u062f\u0627\u062f \u0633\u062a\u0648\u0646\u200c\u0647\u0627",
"Rows": "\u062a\u0639\u062f\u0627\u062f \u0633\u0637\u0631\u200c\u0647\u0627",
"Width": "\u0639\u0631\u0636",
"Height": "\u0627\u0631\u062a\u0641\u0627\u0639",
"Cell spacing": "\u0641\u0627\u0635\u0644\u0647\u200c\u06cc \u0628\u06cc\u0646 \u0633\u0644\u0648\u0644 \u0647\u0627",
"Cell padding": "\u062d\u0627\u0634\u06cc\u0647 \u0633\u0644\u0648\u0644 \u0647\u0627",
"Show caption": "\u0646\u0645\u0627\u06cc\u0634 \u0639\u0646\u0648\u0627\u0646",
"Left": "\u0686\u067e",
"Center": "\u0648\u0633\u0637",
"Right": "\u0631\u0627\u0633\u062a",
"Cell type": "\u0646\u0648\u0639 \u0633\u0644\u0648\u0644",
"Scope": "\u0645\u062d\u062f\u0648\u062f\u0647\u200c\u06cc \u0639\u0646\u0648\u0627\u0646",
"Alignment": "\u0631\u062f\u06cc\u0641 \u0628\u0646\u062f\u06cc \u0646\u0648\u0634\u062a\u0647",
"H Align": "H Align",
"V Align": "V Align",
"Top": "Top",
"Middle": "Middle",
"Bottom": "Bottom",
"Header cell": "\u0633\u0631\u0622\u06cc\u0646\u062f \u0633\u0644\u0648\u0644",
"Row group": "\u06af\u0631\u0648\u0647 \u0633\u0637\u0631",
"Column group": "\u06af\u0631\u0648\u0647 \u0633\u062a\u0648\u0646",
"Row type": "\u0646\u0648\u0639 \u0633\u0637\u0631",
"Header": "\u0633\u0631\u0622\u06cc\u0646\u062f",
"Body": "\u0628\u062f\u0646\u0647",
"Footer": "\u067e\u0627\u0646\u0648\u06cc\u0633",
"Border color": "Border color",
"Insert template...": "\u062f\u0631\u062c \u0627\u0644\u06af\u0648...",
"Templates": "\u0627\u0644\u06af\u0648\u200c\u0647\u0627",
"Template": "\u0627\u0644\u06af\u0648",
"Text color": "\u0631\u0646\u06af \u0645\u062a\u0646",
"Background color": "\u0631\u0646\u06af \u0632\u0645\u06cc\u0646\u0647 \u0645\u062a\u0646",
"Custom...": "Custom...",
"Custom color": "Custom color",
"No color": "No color",
"Remove color": "\u062d\u0630\u0641 \u0631\u0646\u06af",
"Table of Contents": "Table of Contents",
"Show blocks": "\u0646\u0645\u0627\u06cc\u0634 \u0628\u062e\u0634\u200c\u0647\u0627",
"Show invisible characters": "\u0646\u0645\u0627\u06cc\u0634 \u06a9\u0627\u0631\u0627\u06a9\u062a\u0631\u0647\u0627\u06cc \u063a\u06cc\u0631 \u0642\u0627\u0628\u0644 \u0686\u0627\u067e",
"Word count": "\u062a\u0639\u062f\u0627\u062f \u0648\u0627\u0698\u0647\u200c\u0647\u0627",
"Count": "\u0634\u0645\u0627\u0631\u0634",
"Document": "\u0633\u0646\u062f",
"Selection": "\u0627\u0646\u062a\u062e\u0627\u0628",
"Words": "\u06a9\u0644\u0645\u0627\u062a",
"Words: {0}": "\u06a9\u0644\u0645\u0627\u062a : {0}",
"{0} words": "{0} \u0648\u0627\u0698\u0647",
"File": "\u067e\u0631\u0648\u0646\u062f\u0647",
"Edit": "\u0648\u06cc\u0631\u0627\u06cc\u0634",
"Insert": "\u0627\u0636\u0627\u0641\u0647 \u06a9\u0631\u062f\u0646",
"View": "\u0646\u0645\u0627\u06cc\u0634",
"Format": "\u0642\u0627\u0644\u0628",
"Table": "\u062c\u062f\u0648\u0644",
"Tools": "\u0627\u0628\u0632\u0627\u0631\u0647\u0627",
"Powered by {0}": "\u0642\u0648\u062a\u200c\u06af\u0631\u0641\u062a\u0647 \u0627\u0632 {0}",
"Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "\u0648\u06cc\u0631\u0627\u06cc\u0634\u06af\u0631 \u067e\u06cc\u0634\u0631\u0641\u062a\u0647\u200c\u06cc \u0645\u062a\u0646. \u0628\u0631\u0627\u06cc \u062f\u0633\u062a\u0631\u0633\u06cc \u0628\u0647 \u0645\u0646\u0648 \u06a9\u0644\u06cc\u062f\u0647\u0627\u06cc ALT-F9\u060c \u0646\u0648\u0627\u0631 \u0627\u0628\u0632\u0627\u0631 ALT-F10 \u0648 \u0628\u0631\u0627\u06cc \u0645\u0634\u0627\u0647\u062f\u0647\u200c\u06cc \u0631\u0627\u0647\u0646\u0645\u0627 ALT-0 \u0631\u0627 \u0641\u0634\u0627\u0631 \u062f\u0647\u06cc\u062f.",
"Image title": "\u0639\u0646\u0648\u0627\u0646 \u062a\u0635\u0648\u06cc\u0631",
"Border width": "\u0639\u0631\u0636 \u062d\u0627\u0634\u06cc\u0647",
"Border style": "\u0633\u0628\u06a9 \u062d\u0627\u0634\u06cc\u0647",
"Error": "\u062e\u0637\u0627",
"Warn": "\u0647\u0634\u062f\u0627\u0631",
"Valid": "\u0645\u0639\u062a\u0628\u0631",
"To open the popup, press Shift+Enter": "\u062c\u0647\u062a \u0628\u0627\u0632 \u06a9\u0631\u062f\u0646 \u067e\u0646\u062c\u0631\u0647 \u0628\u0627\u0632\u0634\u0648\u060c \u06a9\u0644\u06cc\u062f\u0647\u0627\u06cc Shift + Enter \u0631\u0627 \u0641\u0634\u0627\u0631 \u062f\u0647\u06cc\u062f.",
"Rich Text Area. Press ALT-0 for help.": "\u0646\u0627\u062d\u06cc\u0647 \u0645\u062a\u0646 \u063a\u0646\u06cc. \u062c\u0647\u062a \u0645\u0634\u0627\u0647\u062f\u0647\u0654 \u0631\u0627\u0647\u0646\u0645\u0627 \u06a9\u0644\u06cc\u062f\u0647\u0627\u06cc ALT + 0 \u0631\u0627 \u0641\u0634\u0627\u0631 \u062f\u0647\u06cc\u062f.",
"System Font": "\u0641\u0648\u0646\u062a \u0633\u06cc\u0633\u062a\u0645\u06cc",
"Failed to upload image: {0}": "\u0639\u062f\u0645 \u0645\u0648\u0641\u0642\u06cc\u062a \u062f\u0631 \u0628\u0627\u0631\u06af\u0630\u0627\u0631\u06cc \u062a\u0635\u0648\u06cc\u0631: {0}",
"Failed to load plugin: {0} from url {1}": "\u0639\u062f\u0645 \u0645\u0648\u0641\u0642\u06cc\u062a \u062f\u0631 \u0628\u0627\u0631\u06af\u0630\u0627\u0631\u06cc \u0627\u0641\u0632\u0648\u0646\u0647: {0} \u0627\u0632 \u0646\u0634\u0627\u0646\u06cc \u0648\u0628 {1}",
"Failed to load plugin url: {0}": "\u0639\u062f\u0645 \u0645\u0648\u0641\u0642\u06cc\u062a \u062f\u0631 \u0628\u0627\u0631\u06af\u0630\u0627\u0631\u06cc \u0646\u0634\u0627\u0646\u06cc \u0648\u0628 \u0627\u0641\u0632\u0648\u0646\u0647: {0}",
"Failed to initialize plugin: {0}": "\u0639\u062f\u0645 \u0645\u0648\u0641\u0642\u06cc\u062a \u062f\u0631 \u0631\u0627\u0647\u200c\u0627\u0646\u062f\u0627\u0632\u06cc \u0627\u0641\u0632\u0648\u0646\u0647: {0}",
"example": "\u0645\u062b\u0627\u0644",
"Search": "\u062c\u0633\u062a\u062c\u0648",
"All": "\u0647\u0645\u0647",
"Currency": "\u0627\u0631\u0632",
"Text": "\u0645\u062a\u0646",
"Quotations": "\u0646\u0642\u0644\u200c\u0642\u0648\u0644\u200c\u0647\u0627",
"Mathematical": "\u0631\u06cc\u0627\u0636\u06cc",
"Extended Latin": "\u0644\u0627\u062a\u06cc\u0646 \u06af\u0633\u062a\u0631\u062f\u0647",
"Symbols": "\u0646\u0645\u0627\u062f\u0647\u0627",
"Arrows": "\u067e\u06cc\u06a9\u0627\u0646\u200c\u0647\u0627",
"User Defined": "\u0628\u0647 \u062e\u0648\u0627\u0633\u062a \u06a9\u0627\u0631\u0628\u0631",
"dollar sign": "\u0646\u0645\u0627\u062f \u062f\u0644\u0627\u0631",
"currency sign": "\u0646\u0645\u0627\u062f \u0627\u0631\u0632",
"euro-currency sign": "\u0646\u0645\u0627\u062f \u06cc\u0648\u0631\u0648",
"colon sign": "\u0646\u0645\u0627\u062f \u062f\u0648\u0646\u0642\u0637\u0647",
"cruzeiro sign": "\u0646\u0645\u0627\u062f \u06a9\u0631\u0648\u0632\u06cc\u0631\u0648",
"french franc sign": "\u0646\u0645\u0627\u062f \u0641\u0631\u0627\u0646\u06a9 \u0641\u0631\u0627\u0646\u0633\u0647",
"lira sign": "\u0646\u0645\u0627\u062f \u0644\u06cc\u0631\u0647",
"mill sign": "\u0646\u0645\u0627\u062f \u0645\u06cc\u0644",
"naira sign": "\u0646\u0645\u0627\u062f \u0646\u0627\u06cc\u0631\u0627",
"peseta sign": "\u0646\u0645\u0627\u062f \u067e\u0632\u062a\u0627",
"rupee sign": "\u0646\u0645\u0627\u062f \u0631\u0648\u067e\u06cc\u0647",
"won sign": "\u0646\u0645\u0627\u062f \u0648\u0648\u0646",
"new sheqel sign": "\u0646\u0645\u0627\u062f \u0634\u06a9\u0644 \u062c\u062f\u06cc\u062f",
"dong sign": "\u0646\u0645\u0627\u062f \u062f\u0627\u0646\u06af",
"kip sign": "\u0646\u0645\u0627\u062f \u06a9\u06cc\u067e",
"tugrik sign": "\u0646\u0645\u0627\u062f \u062a\u0648\u06af\u0631\u0648\u06af",
"drachma sign": "\u0646\u0645\u0627\u062f \u062f\u0631\u0627\u062e\u0645\u0627",
"german penny symbol": "\u0646\u0645\u0627\u062f \u067e\u0646\u06cc \u0622\u0644\u0645\u0627\u0646\u06cc",
"peso sign": "\u0646\u0645\u0627\u062f \u067e\u0632\u0648",
"guarani sign": "\u0646\u0645\u0627\u062f \u06af\u0648\u0627\u0631\u0627\u0646\u06cc",
"austral sign": "\u0646\u0645\u0627\u062f \u0622\u0633\u062a\u0631\u0627\u0644",
"hryvnia sign": "\u0646\u0645\u0627\u062f \u06af\u0631\u06cc\u0648\u0646\u0627",
"cedi sign": "\u0646\u0645\u0627\u062f \u0633\u062f\u06cc",
"livre tournois sign": "\u0646\u0645\u0627\u062f \u0644\u06cc\u0648\u0631\u0647 \u062a\u0648\u0631\u0646\u0648\u0627",
"spesmilo sign": "\u0646\u0645\u0627\u062f \u0627\u0633\u067e\u0633\u0645\u06cc\u0644\u0648",
"tenge sign": "\u0646\u0645\u0627\u062f \u062a\u0646\u06af\u0647",
"indian rupee sign": "\u0646\u0645\u0627\u062f \u0631\u0648\u067e\u06cc\u0647 \u0647\u0646\u062f\u06cc",
"turkish lira sign": "\u0646\u0645\u0627\u062f \u0644\u06cc\u0631\u0647 \u062a\u0631\u06a9\u06cc",
"nordic mark sign": "\u0646\u0645\u0627\u062f \u0645\u0627\u0631\u06a9 \u0646\u0631\u0648\u0698",
"manat sign": "\u0646\u0645\u0627\u062f \u0645\u0646\u0627\u062a",
"ruble sign": "\u0646\u0645\u0627\u062f \u0631\u0648\u0628\u0644",
"yen character": "\u0646\u0648\u06cc\u0633\u0647 \u06cc\u0646",
"yuan character": "\u0646\u0648\u06cc\u0633\u0647 \u06cc\u0648\u0627\u0646",
"yuan character, in hong kong and taiwan": "\u0646\u0648\u06cc\u0633\u0647 \u06cc\u0648\u0627\u0646\u060c \u062f\u0631 \u0647\u0646\u06af\u200c\u06a9\u0646\u06af \u0648 \u062a\u0627\u06cc\u0648\u0627\u0646",
"yen\/yuan character variant one": "\u0646\u0648\u06cc\u0633\u0647 \u062c\u0627\u06cc\u06af\u0632\u06cc\u0646 \u06cc\u0646\/\u06cc\u0648\u0627\u0646",
"Loading emoticons...": "\u062f\u0631 \u062d\u0627\u0644 \u0628\u0627\u0631\u06af\u0630\u0627\u0631\u06cc \u0634\u06a9\u0644\u06a9\u200c\u0647\u0627...",
"Could not load emoticons": "\u0634\u06a9\u0644\u06a9\u200c\u0647\u0627 \u0628\u0627\u0631\u06af\u0630\u0627\u0631\u06cc \u0646\u0634\u062f\u0646\u062f",
"People": "\u0627\u0641\u0631\u0627\u062f",
"Animals and Nature": "\u062d\u06cc\u0648\u0627\u0646\u0627\u062a \u0648 \u0637\u0628\u06cc\u0639\u062a",
"Food and Drink": "\u063a\u0630\u0627 \u0648 \u0646\u0648\u0634\u06cc\u062f\u0646\u06cc",
"Activity": "\u0641\u0639\u0627\u0644\u06cc\u062a",
"Travel and Places": "\u0633\u0641\u0631 \u0648 \u0627\u0645\u0627\u06a9\u0646",
"Objects": "\u0627\u0634\u06cc\u0627",
"Flags": "\u067e\u0631\u0686\u0645\u200c\u0647\u0627",
"Characters": "\u0646\u0648\u06cc\u0633\u0647\u200c\u0647\u0627",
"Characters (no spaces)": "\u0646\u0648\u06cc\u0633\u0647 \u0647\u0627 (\u0628\u062f\u0648\u0646 \u0641\u0627\u0635\u0644\u0647)",
"{0} characters": "{0} \u06a9\u0627\u0631\u0627\u06a9\u062a\u0631",
"Error: Form submit field collision.": "\u062e\u0637\u0627: \u062a\u062f\u0627\u062e\u0644 \u062f\u0631 \u062b\u0628\u062a \u0641\u0631\u0645.",
"Error: No form element found.": "\u062e\u0637\u0627: \u0647\u06cc\u0686 \u0627\u0644\u0645\u0627\u0646 \u0641\u0631\u0645\u06cc \u06cc\u0627\u0641\u062a \u0646\u0634\u062f.",
"Update": "\u0628\u0647\u200c\u0631\u0648\u0632\u0631\u0633\u0627\u0646\u06cc",
"Color swatch": "\u0646\u0645\u0648\u0646\u0647 \u0631\u0646\u06af",
"Turquoise": "\u0641\u06cc\u0631\u0648\u0632\u0647\u200c\u0627\u06cc",
"Green": "\u0633\u0628\u0632",
"Blue": "\u0622\u0628\u06cc",
"Purple": "\u0628\u0646\u0641\u0634",
"Navy Blue": "\u0633\u0631\u0645\u0647\u200c\u0627\u06cc",
"Dark Turquoise": "\u0641\u06cc\u0631\u0648\u0632\u0647\u200c\u0627\u06cc \u062a\u06cc\u0631\u0647",
"Dark Green": "\u0633\u0628\u0632 \u062a\u06cc\u0631\u0647",
"Medium Blue": "\u0622\u0628\u06cc \u0633\u06cc\u0631",
"Medium Purple": "\u0622\u0628\u06cc \u0628\u0646\u0641\u0634",
"Midnight Blue": "\u0622\u0628\u06cc \u0646\u0641\u062a\u06cc",
"Yellow": "\u0632\u0631\u062f",
"Orange": "\u0646\u0627\u0631\u0646\u062c\u06cc",
"Red": "\u0642\u0631\u0645\u0632",
"Light Gray": "\u062e\u0627\u06a9\u0633\u062a\u0631\u06cc \u0631\u0648\u0634\u0646",
"Gray": "\u062e\u0627\u06a9\u0633\u062a\u0631\u06cc",
"Dark Yellow": "\u0632\u0631\u062f \u062a\u06cc\u0631\u0647",
"Dark Orange": "\u0646\u0627\u0631\u0646\u062c\u06cc \u062a\u06cc\u0631\u0647",
"Dark Red": "\u0642\u0631\u0645\u0632 \u062a\u06cc\u0631\u0647",
"Medium Gray": "\u062e\u0627\u06a9\u0633\u062a\u0631\u06cc \u0646\u06cc\u0645\u0647\u200c\u0631\u0648\u0634\u0646",
"Dark Gray": "\u062e\u0627\u06a9\u0633\u062a\u0631\u06cc \u062a\u06cc\u0631\u0647",
"Light Green": "\u0633\u0628\u0632 \u0631\u0648\u0634\u0646",
"Light Yellow": "\u0632\u0631\u062f \u0631\u0648\u0634\u0646",
"Light Red": "\u0642\u0631\u0645\u0632 \u0631\u0648\u0634\u0646",
"Light Purple": "\u0628\u0646\u0641\u0634 \u0631\u0648\u0634\u0646",
"Light Blue": "\u0622\u0628\u06cc \u0631\u0648\u0634\u0646",
"Dark Purple": "\u0628\u0646\u0641\u0634 \u062a\u06cc\u0631\u0647",
"Dark Blue": "\u0622\u0628\u06cc \u062a\u06cc\u0631\u0647",
"Black": "\u0633\u06cc\u0627\u0647",
"White": "\u0633\u0641\u06cc\u062f",
"Switch to or from fullscreen mode": "\u062a\u063a\u06cc\u06cc\u0631 \u0627\u0632 \u062d\u0627\u0644\u062a \u062a\u0645\u0627\u0645\u200c\u0635\u0641\u062d\u0647 \u06cc\u0627 \u0628\u0647 \u062d\u0627\u0644\u062a \u062a\u0645\u0627\u0645\u200c\u0635\u0641\u062d\u0647",
"Open help dialog": "\u0628\u0627\u0632 \u06a9\u0631\u062f\u0646 \u06a9\u0627\u062f\u0631 \u0631\u0627\u0647\u0646\u0645\u0627",
"history": "\u062a\u0627\u0631\u06cc\u062e\u0686\u0647",
"styles": "\u0633\u0628\u06a9\u200c\u0647\u0627",
"formatting": "\u0642\u0627\u0644\u0628\u200c\u0628\u0646\u062f\u06cc",
"alignment": "\u062a\u0631\u0627\u0632\u0628\u0646\u062f\u06cc",
"indentation": "\u062a\u0648\u0631\u0641\u062a\u06af\u06cc",
"permanent pen": "\u0642\u0644\u0645 \u062f\u0627\u0626\u0645\u06cc",
"comments": "\u0646\u0638\u0631\u0627\u062a",
"Format Painter": "\u0646\u0642\u0627\u0634 \u0641\u0631\u0645\u062a",
"Insert\/edit iframe": "\u062f\u0631\u062c\/\u0648\u06cc\u0631\u0627\u06cc\u0634 iframe",
"Capitalization": "\u062d\u0631\u0648\u0641 \u0628\u0632\u0631\u06af",
"lowercase": "\u062d\u0631\u0648\u0641 \u06a9\u0648\u0686\u06a9",
"UPPERCASE": "\u062d\u0631\u0648\u0641 \u0628\u0632\u0631\u06af",
"Title Case": "\u062d\u0631\u0648\u0641 \u062a\u06cc\u062a\u0631\u06cc",
"Permanent Pen Properties": "\u0645\u0634\u062e\u0635\u0627\u062a \u062f\u0627\u0626\u0645\u06cc \u0642\u0644\u0645",
"Permanent pen properties...": "\u0645\u0634\u062e\u0635\u0627\u062a \u062f\u0627\u0626\u0645\u06cc \u0642\u0644\u0645...",
"Font": "\u0641\u0648\u0646\u062a",
"Size": "\u0627\u0646\u062f\u0627\u0632\u0647",
"More...": "\u0628\u06cc\u0634\u062a\u0631...",
"Spellcheck Language": "\u0632\u0628\u0627\u0646 \u0686\u06a9 \u0627\u0633\u067e\u0644\u06cc\u0646\u06af",
"Select...": "\u0627\u0646\u062a\u062e\u0627\u0628...",
"Preferences": "\u062a\u0631\u062c\u06cc\u062d\u0627\u062a",
"Yes": "\u0628\u0644\u0647",
"No": "\u062e\u06cc\u0631",
"Keyboard Navigation": "\u0645\u0631\u0648\u0631 \u0628\u0627 \u0635\u0641\u062d\u0647 \u06a9\u0644\u06cc\u062f",
"Version": "\u0646\u0633\u062e\u0647",
"Anchor": "\u0644\u0646\u06af\u0631 - \u0644\u06cc\u0646\u06a9",
"Special character": "\u06a9\u0627\u0631\u0627\u06a9\u062a\u0631 \u0647\u0627\u06cc \u062e\u0627\u0635",
"Color": "Color",
"Emoticons": "\u0634\u06a9\u0644\u06a9\u200c\u0647\u0627",
"Document properties": "\u0648\u06cc\u0698\u06af\u06cc\u200c\u0647\u0627\u06cc \u0633\u0646\u062f",
"Image": "\u0639\u06a9\u0633",
"Insert link": "\u0627\u0636\u0627\u0641\u0647 \u06a9\u0631\u062f\u0646 \u0644\u06cc\u0646\u06a9",
"Link": "Link",
"Target": "\u0646\u062d\u0648\u0647 \u0628\u0627\u0632 \u0634\u062f\u0646 \u062f\u0631 \u0645\u0631\u0648\u0631\u06af\u0631",
"Media": "Media",
"Poster": "\u067e\u0648\u0633\u062a\u0631",
"Print": "\u0686\u0627\u067e",
"Whole words": "\u0647\u0645\u0647 \u06a9\u0644\u0645\u0647\u200c\u0647\u0627",
"Find and replace": "\u062c\u0633\u062a\u200c\u0648\u200c\u062c\u0648 \u0648 \u062c\u0627\u06cc\u06af\u0632\u06cc\u0646 \u06a9\u0631\u062f\u0646",
"Prev": "\u0642\u0628\u0644\u06cc",
"Spellcheck": "\u0628\u0631\u0631\u0633\u06cc \u0627\u0645\u0644\u0627\u06cc\u06cc",
"Caption": "\u0639\u0646\u0648\u0627\u0646",
"Insert template": "\u0627\u0636\u0627\u0641\u0647 \u06a9\u0631\u062f\u0646 \u0627\u0644\u06af\u0648",
"_dir": "rtl"
});